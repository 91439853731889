<template>

  <nav id="range-select">
    <label>日付</label>
    <div>
      <input
        type="date"
        class="form-control"
        v-model="from"
        :max="today"
        @change="setFrom"
      >
    </div>
    <span>〜</span>
    <div>
      <input
        type="date"
        class="form-control"
        v-model="to"
        :max="today"
        @change="setTo"
      >
    </div>
  </nav>

  <table id="checklist-status" class="table table-striped">
    <tbody>
      <tr
        v-for="row in checkList"
        :key="row.dailycheck_id"
      >
        <td class='date'>
          {{row.inspection_execute_datetime}}
          <div class='tantou'>{{row.inspector}}</div>
        </td>
        <td>
          <div v-html="row.str"></div>
          <div
            v-if="row.memo"
            class="memo"
          >{{row.memo}}</div>
        </td> 
      </tr>
    </tbody>
  </table>

</template>

<script>
import axios from "axios"
export default {
  data() {
    return {
      from: "",
      to: "",
      checkList: {}
    }
  },
  mounted() {
    let today = new Date()
    this.to = "" + today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2)
    today.setDate(today.getDate() - 7);
    this.from = "" + today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2)

    this.getDailyCheckStatus()
    console.log(this.$route.params.id)
  },
  methods: {
    setFrom(date) {
      this.from = date.target.value
      this.getDailyCheckStatus()
    },
    setTo(date) {
      this.to = date.target.value
      this.getDailyCheckStatus()
    },
    getDailyCheckStatus() {
    axios
      .post("/wp-json/pasti/v1/dailycheckstatus/", {
        id: this.$route.params.id,
        from: this.from,
        to: this.to,
      })
      .then( response => {
        console.log(response.data)
        this.checkList = response.data
      })
      .catch( error => {
        console.log(error);
      })
    }
  },
  computed: {
    today() {
      let today = new Date()
      today = "" + today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2)
      console.log(today)
      return today
    }
  }
}
</script>

