<template>
  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#staff"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">ブリーダー</h1>
      </div>
    </div>
  </header>

  <main class="p-3">

    <SummaryTable />

    <Controls
      @selected="setPane"
      @csvDL="csvDL"
    />

    <Animals
      v-if="pane==='animals'"
      :csv="csv"
    />
    <Vaccinated
      v-if="pane==='vaccinated'"
      :csv="csv"
    />
    <DailyCheck v-else-if="pane==='dailycheck'" />

  </main>

</template>

<script>
import SummaryTable from "@/components/Breeder/SummaryTable"
import Controls from "@/components/Breeder/Controls"
import Animals from "@/components/Breeder/Animals"
import Vaccinated from "@/components/Breeder/AnimalsVaccinated"
import DailyCheck from "@/components/Breeder/DailyCheck"
export default {
  components: {
    SummaryTable,
    Controls,
    Animals,
    Vaccinated,
    DailyCheck
  },
  data() {
    return {
      pane: 'animals',
      csv: false
    }
  },
  methods: {
    setPane(pane) {
      this.pane = pane
    },
    csvDL() {
      this.csv = true
    }
  }
}
</script>


