<template>
  <nav class="mb-4">
    <div>
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-primary"
          :class="{ active: all }"
          @click="selectAll"
        >ALL</button>
        <button
          type="button"
          class="btn btn-primary"
          :class="{ active: male }"
          @click="selectMale"
        >オス</button>
        <button
          type="button"
          class="btn btn-primary"
          :class="{ active: female }"
          @click="selectFemale"
        >メス</button>
        <button
          type="button"
          class="btn btn-primary"
          @click="selectAge"
        >年齢順</button>
      </div>
      <div class="input-group ms-3">
        <input
          type="text"
          class="form-control"
          placeholder="マイクロチップ番号"
          v-model="searchStr"
        >
      </div>    
    </div>
  </nav>
</template>

<script>
export default {
  props: ["order"],
  emits: ["restrict", "orderbyAge", "search"],
  data() {
    return {
      all: true,
      male: false,
      female: false,
      search: "",
    }
  },
  methods: {
    selectAll() {
      this.$emit('restrict', "none")
      this.all = true
      this.male = false
      this.female = false
    },
    selectMale() {
      this.$emit('restrict', "male")
      this.all = false
      this.male = true
      this.female = false
    },
    selectFemale() {
      this.$emit('restrict', "female")
      this.all = false
      this.male = false
      this.female = true
    },
    selectAge() {
      this.$emit('orderbyAge')
    },
    sanitize(str) {
      return String(str).replace(/&/g,"&amp;")
        .replace(/"/g,"&quot;")
        .replace(/</g,"&lt;")
        .replace(/>/g,"&gt;")
    },
  },
  computed: {
    searchStr: {
      get () {
        return this.search
      },
      set (value) {
        this.selectAll()
        this.search = this.sanitize(value)
        this.$emit('search', this.search)
        //this.$store.dispatch('Kennel/searchAnimals', this.search)
      }
    }
  },
}
</script>

<style scoped>
  nav {
    display: flex;
    justify-content: space-between;
  }
  nav > * {}
  nav > div {
    flex: 1;
    display: flex;
  }
  nav > button {
    width: 80px;
    flex-basis: 80px;
  }
  .btn-group {
    width: 60%;
    flex-basis: 60%;
  }
  .input-group {
    flex: 1;
  }
</style>