<template>
  <section id="animal-list">

    <AnimalsNavi
      :order="order"
      @restrict="restrict"
      @orderbyAge="orderbyAge"
      @search="search"
    />

    <ul
      id="kennel-yard"
      :class="listClass"
    >

      <li
        v-for="animal in animalList"
        :key="animal.id"
        :class="animal.sex"
        @click="showDetail(animal.id)"
      >
        <div class="pic">
          <img :src="animal.thumbUrl">
        </div>
        <div class="desc">
          <h5>{{animal.name}}</h5>
          <span class="mcid"><span class="badge bg-primary">ID {{animal.number}}</span></span>
          <div class="meta">
            <span class="age"><span class="badge bg-primary">{{animal.age}}才</span></span>
            <span
              v-if="animal.sex==='female'"
              class="birth"
            ><span class="badge bg-primary">出産回数 {{animal.numBirth}}</span></span>
          </div>
        </div>
      </li>

    </ul>
  </section>
</template>

<script>
import axios from "axios"
import AnimalsNavi from "@/components/Breeder/AnimalsNavi"
export default {
  props: ["csv"],
  components: {
    AnimalsNavi
  },
  data() {
    return {
      animalList: [],
      preserved: [],
      listClass: null,
      order: false
    }
  },
  mounted() {
    axios
      .get("/wp-json/pasti/v1/breeder/" + this.$route.params.id + "/animals")
      .then( response => {
        console.log(response.data)
        this.animalList = response.data
        this.preserved = response.data
      })
      .catch( error => {
        console.log(error);
      })
  },
  methods: {
    restrict(data) {
      this.listClass = data
    },
    orderbyAge() {
      if(this.order){
        this.animalList.sort(function(a, b) {
          return (a.age < b.age) ? -1 : 1
        })
      }else{
        this.animalList.sort(function(a, b) {
          return (a.age > b.age) ? -1 : 1
        })
      }
      this.order = !this.order
    },
    search(str) {
      this.animalList = this.preserved
      let extracted = this.animalList.filter( row => row.number.indexOf(str) != -1 )
      this.animalList = extracted
    },
    showDetail(id) {
      this.$router.push('/animal/' + id)
    },
    csvDL() {
      let csv_string  = "";
      let tmp_array = ["所属","名前","マイクロチップno.","年齢","出産回数","犬種","毛色","生年月日","ワクチン接種日"]
      csv_string += tmp_array.join(',')
      csv_string += '\r\n'      

      let animalList = {...this.animalList}
      let breeder_name = ""
      
      Object.keys(animalList).forEach(function (key) {
        let row = {...animalList[key]}
        breeder_name = row["breeder_name"]
        tmp_array = []
        tmp_array.push(row["breeder_name"])
        tmp_array.push(row["name"])
        tmp_array.push(row["number"])
        tmp_array.push(row["age"])
        tmp_array.push(row["numBirth"])
        tmp_array.push(row["species"])
        tmp_array.push(row["coat_color"])
        tmp_array.push(row["birthday"])
        tmp_array.push(row["vaccination_date"])
        csv_string += tmp_array.join(',')
        csv_string += '\r\n'
      })
      
      let file_name  = `${breeder_name}_保有動物リスト.csv`
      let blob = new Blob([csv_string], {type: "text/csv"})
      let uri = URL.createObjectURL(blob)

      let link = document.createElement("a")
      link.download = file_name
      link.href = uri

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      link = null
    }
  },
  watch: {
    csv(newVal) {
      if(newVal) {
        this.csvDL()
      }
    }
  }
}
</script>

