<template>
  <nav id="breeder-control" class="mb-3">

    <ul class="nav nav-pills">
      <li class="nav-item me-2">
        <span
          :class="[{active: selected==='animals'}, 'nav-link']"
          @click="setpane('animals')"

        >飼養動物</span>
      </li>
      <li class="nav-item">
        <span
          :class="[{active: selected==='vaccinated'}, 'nav-link']"
          @click="setpane('vaccinated')"

        >ワクチン接種済</span>
      </li>
      <li class="nav-item">
        <span
          :class="[{active: selected==='dailycheck'}, 'nav-link']"
          @click="setpane('dailycheck')"
        >デイリーチェック実施状況</span>
      </li>
    </ul>

    <div
      class="sub-navi"
      v-if="selected==='animals'||selected==='vaccinated'"
      @click="$emit('csvDL')"
    >
      <button class="btn btn-primary ms-2">CSVダウンロード</button>
    </div>


  </nav>
</template>

<script>
export default {
  emits: ["selected", "csvDL"],
  data() {
    return {
      selected: "animals"
    }
  },
  methods: {
    setpane(target) {
      this.selected = target
      this.$emit("selected", target)
    },
  },
}
</script>