<template>
  <div class="breederinfo datasheet">
    <dl>
      <dt>ブリーダー名</dt><dd>{{breederInfo.author_name}}</dd>
    </dl>
    <dl>
      <dt>会社名</dt><dd>{{breederInfo.company_name}}</dd>
    </dl>
    <dl>
      <dt>代表者</dt><dd>{{breederInfo.representative}}</dd>
    </dl>
    <dl>
      <dt>所在地</dt><dd>{{breederInfo.addr}}</dd>
    </dl>
    <dl>
      <dt>電話番号</dt><dd>{{breederInfo.tel}}</dd>
    </dl>
    <dl>
      <dt>電話番号(携帯)</dt><dd>{{breederInfo.keitai}}</dd>
    </dl>
    <dl>
      <dt>認可番号</dt><dd>{{breederInfo.certifiednum}}</dd>
    </dl>
    <dl>
      <dt>保有頭数</dt><dd>{{breederInfo.num_of_owned_animals}}</dd>
    </dl>
    <dl>
      <dt>スタッフ数</dt><dd>{{breederInfo.num_of_staff}}</dd>
    </dl>
    <dl></dl>
  </div>
</template>

<script>
import axios from "axios"
export default {
  data() {
    return {
      breederInfo: {}
    }
  },
  mounted() {
    axios
      .get("/wp-json/pasti/v1/breeder/" + this.$route.params.id)
      .then( response => {
        this.breederInfo = response.data
      })
      .catch( error => {
        console.log(error);
      })
  }
}
</script>